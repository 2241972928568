import React from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';

const Checkpoints = () => {
    const checkpoints = (typeof window !== 'undefined') ? [
        {
            label: 'Choose module',
            url: '/shop/',
            active: window && window.location.href.indexOf('/shop') > -1,
            complete: (
                (window && window.location.href.indexOf('/shop/customise/') > -1)
                || (window && window.location.href.indexOf('/shop/checkout/') > -1)
            )
        },
        {
            label: 'Customise module',
            url: '/shop/customise/',
            active: window && window.location.href.indexOf('/shop/customise/') > -1,
            complete: (
                (window && window.location.href.indexOf('/shop/checkout/') > -1)
            )
        },
        {
            label: 'Checkout',
            url: '/shop/checkout/',
            active: window && window.location.href.indexOf('/shop/checkout/') > -1
        }
    ] : [];

    return (
        <div className="checkpoints">
            {checkpoints && checkpoints.map((checkpoint, key) => (
                <Link to={checkpoint.url} activeClassName="_is-active" className={`checkpoints__item${(checkpoint.complete) ? ' _is-complete' : ''}`} key={checkpoint.url}>
                    <span className="checkpoints__counter">
                        {
                            (checkpoint.complete)
                                ? <Icon id="check" />
                                : (
                                    <React.Fragment>
                                        {key + 1}
                                    </React.Fragment>
                                )
                        }
                    </span>
                    <span className="text-label checkpoints__label">{checkpoint.label}</span>
                </Link>
            ))}
        </div>
    );
};


Checkpoints.defaultProps = {
    checkpoints: null
};

export default Checkpoints;
