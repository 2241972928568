import React from 'react';

import Layout from '@layouts/Layout';

import ModuleSelector from '@components/modules/ModuleSelector';
import CreateCallout from '@components/callouts/CreateCallout';
import Checkpoints from '@components/shop/Checkpoints';

const Shop = () => (
    <Layout
        mainClassName="flow--xlarge"
        metaData={{ title: 'Choose your Perfect Pergola' }}
    >
        <header className="hero hero--has-checkpoints">
            <div className="container flow">
                <Checkpoints />
                <h1 className="text-subtitle text-align--center">Choose module</h1>
            </div>
        </header>
        <section className="background-color--chalk flow--medium">
            <ModuleSelector />
        </section>
        <section className="container">
            <CreateCallout />
        </section>
    </Layout>
);

export default Shop;
